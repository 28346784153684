var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:{ 'green lighten-5': _vm.response },attrs:{"flat":""}},[(_vm.showTitle)?_c('v-card-text',{staticClass:"mx-0 pa-0"},[_c('v-row',{staticClass:"mx-1 mb-2 white--text py-3 pl-5",class:{
          'grey darken-1': !_vm.response,
          'green darken-1': _vm.response,
        },attrs:{"dense":""}},[_c('v-col',[_c('h3',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(((_vm.$t("question")) + " " + (_vm.index + 1)))+" "),_c('span',{staticClass:"float-right mr-2"},[_vm._v(" "+_vm._s(!_vm.response ? _vm.$t("pending") : "")+" ")])])])],1)],1):_vm._e(),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-textarea',{attrs:{"flat":"","outlined":"","readonly":"","auto-grow":""},model:{value:(_vm.compData.question),callback:function ($$v) {_vm.$set(_vm.compData, "question", $$v)},expression:"compData.question"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.response),callback:function ($$v) {_vm.response=$$v},expression:"response"}},_vm._l((_vm.compData.question_answers),function(ref){
        var id = ref.id;
        var text = ref.text;
return _c('v-radio',{key:id,staticClass:"py-1 py-md-4 pl-3 text-wrap rounded-lg",class:_vm.setClass(id),attrs:{"color":"red","value":id,"disabled":!!_vm.rightAnswer},on:{"click":function($event){return _vm.testclick(id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"grow"},[_vm._v(_vm._s(text))]),_c('v-col',{staticClass:"shrink"},[(_vm.compRightAnswer === id)?_c('v-chip',{staticClass:"float-right mr-2",attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"label":"","color":"green","text-color":"white"}},[_vm._v("Correcto")]):_vm._e(),(
                        _vm.compRightAnswer &&
                          id === _vm.response &&
                          _vm.compRightAnswer !== id
                      )?_c('v-chip',{staticClass:"float-right mr-2",attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"label":"","color":"red","text-color":"white"}},[_vm._v("Respondido")]):_vm._e()],1)],1)]},proxy:true}],null,true)})}),1)],1)],1)],1),(_vm.compComments)?_c('v-card-text',{staticClass:"my-2"},[_c('v-row',{attrs:{"dense":""}},[(_vm.rightAnswer.comment_right && _vm.response === _vm.compRightAnswer)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"flat":"","outlined":"","readonly":"","auto-grow":"","rows":"1"},model:{value:(_vm.rightAnswer.comment_right),callback:function ($$v) {_vm.$set(_vm.rightAnswer, "comment_right", $$v)},expression:"rightAnswer.comment_right"}})],1):_vm._e(),(_vm.rightAnswer.comment_wrong && _vm.response !== _vm.compRightAnswer)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"flat":"","outlined":"","readonly":"","auto-grow":"","rows":"1","border":""},model:{value:(_vm.rightAnswer.comment_wrong),callback:function ($$v) {_vm.$set(_vm.rightAnswer, "comment_wrong", $$v)},expression:"rightAnswer.comment_wrong"}})],1):_vm._e()],1)],1):_vm._e(),(_vm.alertMsg.show)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-alert',{attrs:{"color":_vm.alertMsg.responseType,"type":"info","value":true}},[_vm._v(" "+_vm._s(_vm.alertMsg.text)+" ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }