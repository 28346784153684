<template>
  <div>
    <v-card flat :class="{ 'green lighten-5': response }">
      <!--   {{ data }} -->
      <v-card-text class="mx-0 pa-0" v-if="showTitle">
        <v-row
          dense
          class="mx-1 mb-2 white--text py-3 pl-5"
          :class="{
            'grey darken-1': !response,
            'green darken-1': response,
          }"
        >
          <v-col>
            <h3 class="text-uppercase">
              {{ `${$t("question")} ${index + 1}` }}
              <span class="float-right mr-2">
                {{ !response ? $t("pending") : "" }}
              </span>
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-textarea
              flat
              outlined
              v-model="compData.question"
              readonly
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-radio-group v-model="response" column>
              <v-radio
                v-for="{ id, text } in compData.question_answers"
                :key="id"
                color="red"
                :value="id"
                class="py-1 py-md-4 pl-3 text-wrap rounded-lg"
                :class="setClass(id)"
                @click="testclick(id)"
                :disabled="!!rightAnswer"
              >
                <!-- :active-class="setClass(id)" -->
                <template v-slot:label>
                  <v-row dense>
                    <v-col class="grow">{{ text }}</v-col>
                    <v-col class="shrink">
                      <v-chip
                        :small="$vuetify.breakpoint.mdAndDown"
                        v-if="compRightAnswer === id"
                        label
                        class="float-right mr-2"
                        color="green"
                        text-color="white"
                        >Correcto</v-chip
                      >
                      <v-chip
                        :small="$vuetify.breakpoint.mdAndDown"
                        v-if="
                          compRightAnswer &&
                            id === response &&
                            compRightAnswer !== id
                        "
                        label
                        class="float-right mr-2"
                        color="red"
                        text-color="white"
                        >Respondido</v-chip
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
              <!--  
                active-class="green ligthen-5"
               
                 -->
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="compComments" class="my-2">
        <v-row dense>
          <v-col
            cols="12"
            v-if="rightAnswer.comment_right && response === compRightAnswer"
          >
            <v-textarea
              flat
              outlined
              v-model="rightAnswer.comment_right"
              readonly
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            v-if="rightAnswer.comment_wrong && response !== compRightAnswer"
          >
            <v-textarea
              flat
              outlined
              v-model="rightAnswer.comment_wrong"
              readonly
              auto-grow
              rows="1"
              border=""
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="alertMsg.show">
        <v-row dense>
          <v-col>
            <v-alert :color="alertMsg.responseType" type="info" :value="true">
              {{ alertMsg.text }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TestTypeOneComp",
  props: ["data", "index", "setAnswer", "rightAnswers", "showTitle"],
  data: () => ({
    response: null,

    rightAnswer: null,

    alertMsg: {
      show: false,
      responseType: "green",
      text: "",
    },
  }),
  computed: {
    compData() {
      const tmp = this.data;
      // generamos una posición random
      tmp.question_answers = randomPosition(this.data.question_answers);
      return tmp;
    },
    compRightAnswer() {
      if (!this.rightAnswer) {
        return null;
      }
      return this.rightAnswer.question_answers[0].id;
    },
    compComments() {
      if (!this.rightAnswer) {
        return false;
      }

      if (this.rightAnswer.comment_right || this.rightAnswer.comment_wrong) {
        return true;
      }
      return false;
    },
    compPosition() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.$vuetify.breakpoint.name;
        case "sm":
          return this.$vuetify.breakpoint.name;
        case "md":
          return this.$vuetify.breakpoint.name;
        case "lg":
          return this.$vuetify.breakpoint.name;
        case "xl":
          return this.$vuetify.breakpoint.name;
      }
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    setClass(id) {
      /*     console.log(
        this.compRightAnswer,
        id,
        this.response,
        this.compRightAnswer
      ); */
      // sin corregir y no seleccionada - por defecto
      if (!this.compRightAnswer && this.response !== id) {
        return "blue lighten-3";
      }
      // sin corregir y seleccionada
      if (!this.compRightAnswer && this.response === id) {
        return "yellow lighten-4 border-selected";
      }
      // corregida y correcta
      if (this.compRightAnswer && id === this.compRightAnswer) {
        return "green lighten-3 border-selected";
      }

      // corregida seleccionada e incorrecta
      if (
        this.compRightAnswer &&
        this.response === id &&
        this.response !== this.compRightAnswer
      ) {
        return "grey lighten-1";
      }

      // por defecto
      return "grey lighten-3";
    },
    testclick(id) {
      if (!this.rightAnswer) {
        this.response = id;
      }
    },
    checkQuestion() {
      if (this.rightAnswer) {
        const result = this.response === this.compRightAnswer;

        // es verdadera
        if (result) {
          this.alertMsg = {
            show: true,
            responseType: "green",
            text: this.$t("right-answer"),
          };
        } else {
          this.alertMsg = {
            show: true,
            responseType: "red",
            text: this.$t("error-answer"),
          };
        }
      }
    },
    resetQuestion() {
      this.response = null;
      this.alertMsg = {
        show: false,
        responseType: "green",
        text: "",
      };
    },
  },
  watch: {
    response(newValue) {
      // console.log("nueva respeusta", newValue, oldValue);

      this.setAnswer({
        question_id: this.data.id,
        answer_id: newValue,
        result: null,
      });
    },
    rightAnswers(newValue) {
      // console.log(newValue, oldValue);
      if (newValue && newValue.length > 0) {
        this.rightAnswer = newValue.find((n) => n.id === this.data.id);
        //console.log("el nuevo valor", this.rightAnswer);
        this.checkQuestion();
      }
    },
  },
};

const randomPosition = (pAnswers) => {
  let answers = [...pAnswers];

  let counter = answers.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;

    // cambiamos la posición conforme al random
    let temp = answers[counter];
    answers[counter] = answers[index];
    answers[index] = temp;
  }
  return answers;
};
</script>

<style lang="scss" scoped>
.border-selected {
  border-top-width: 1.3em;
  border-bottom-width: 1.3em;
  font-weight: bold;
  border-style: solid;
}

.w-100 {
  width: 100% !important;
}
</style>
