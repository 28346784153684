<template>
  <v-row justify="center">
    <v-dialog v-model="show" :max-width="ancho" persistent>
      <v-card>
        <v-card-title
          class="headline"
          v-html="`<b>${$t(head)}</b>`"
        ></v-card-title>

        <v-card-text v-html="`${$t(body)}`"></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="$emit('emitCancel')">
            {{ $t("cancel") }}
          </v-btn>

          <v-btn color="green darken-1" text @click="$emit('emitAccept')">
            {{ $t("accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ModalComp",
  props: ["head", "body", "show", "maxWidth"],
  computed: {
    ancho() {
      if (!this.maxWidth) {
        return 320;
      } else {
        return this.maxWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
