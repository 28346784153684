<template>
  <div v-if="test">
    <v-card>
      <v-app-bar
        color="blue"
        flat
        fixed
        app
        dark
        :height="$vuetify.breakpoint.smAndUp ? 64 : 34"
      >
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title class="px-0">
          <!-- estado actual -->
          <v-chip
            :x-small="$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndUp"
            color="primary"
            text-color="white"
          >
            {{ compStatus }}
          </v-chip>
          <!-- resultado -->
          <v-chip
            class="ml-1"
            v-if="rightAnswers && !counting"
            :x-small="$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndUp"
            color="red"
            text-color="white"
          >
            {{ compResult }}
          </v-chip>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn small text @click="checkAll" v-if="!rightAnswers">
          {{ $t("check") }}
          <v-icon right>mdi-database-check</v-icon>
        </v-btn>
        <v-btn x-small text @click="restartTest" v-else>
          {{ $t("restart") }}
          <v-icon right>mdi-restart</v-icon>
        </v-btn>

        <v-btn icon @click="exitTest">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <h2>{{ test.name }}</h2>
      </v-card-text>
      <v-divider class="mx-2"></v-divider>
      <!-- mostramos slideShow una pregunta a la vez -->
      <v-card-text v-if="test.show_one_question === 1">
        <!-- STEPPER -->
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-row dense class="pa-2 text-center mt-3">
              <v-col>
                <h2>
                  {{ `${$t("question")} ${e1} ` }}
                </h2>
              </v-col>
            </v-row>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              class="pa-1"
              v-for="(question, index) in questions"
              :key="question.id"
              :step="index + 1"
            >
              <DoTestOneQuestionComp
                :showTitle="false"
                :setAnswer="setAnswer"
                :data="question"
                :index="index"
                :rightAnswers="rightAnswers"
              ></DoTestOneQuestionComp>

              <v-btn
                class="float-right mt-2"
                :block="$vuetify.breakpoint.smAndDown"
                color="primary"
                @click="nextStep(index + 1)"
              >
                {{ $t("continue") }}
              </v-btn>

              <!--  <v-btn text>
                {{ $t("cancel") }}
              </v-btn> -->
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <!-- mostramos todas las preguntas a la vez -->
      <v-card-text v-else class="px-0 px-md-2">
        <div v-for="(question, index) in questions" :key="question.id">
          <DoTestOneQuestionComp
            :showTitle="true"
            :setAnswer="setAnswer"
            :data="question"
            :index="index"
            :rightAnswers="rightAnswers"
          ></DoTestOneQuestionComp>
        </div>
      </v-card-text>
    </v-card>
    <ModalComp
      :head="modal.head"
      :body="modal.body"
      :show="modal.show"
      :maxWidth="modal.maxWidth"
      @emitCancel="modal.show = false"
      @emitAccept="aceptarModalAll"
    />
  </div>
</template>

<script>
import { doTestSrv } from "@/_services";

import DoTestOneQuestionComp from "./parts/DoTestOneQuestionComp";
import ModalComp from "@/components/common/ModalComp";

export default {
  name: "doTestMainComp",
  components: {
    DoTestOneQuestionComp,
    ModalComp,
  },
  data: () => ({
    // stepper
    e1: 1,
    counting: false,
    pending: true,
    test: null,
    questions: [],
    answers: [],
    rightAnswers: null,
    modal: {
      show: false,
      head: null,
      body: null,
      maxWidth: 400,
    },
  }),
  methods: {
    restartTest() {
      this.pending = true;
      this.test = null;
      this.questions = [];
      this.answers = [];
      this.rightAnswers = null;
      (this.modal = {
        show: false,
        head: null,
        body: null,
        maxWidth: 400,
      }),
        this.getData();
    },
    setAnswer(data) {
      // console.log(data);
      let tmp = this.answers.find((a) => a.question_id === data.question_id);

      if (!tmp) {
        this.answers.push(data);
      } else {
        tmp.answer_id = data.answer_id;
      }
      /*  console.log({
        ...this.answers.find((a) => a.question_id === data.question_id),
      }); */
    },
    checkAll() {
      const answered = this.answers.length;
      const questions = this.questions.length;
      if (answered < questions) {
        this.modal.head = "attention";
        this.modal.body = "all-questions-not-answered";
        this.modal.show = true;
      } else {
        this.aceptarModalAll();
      }
    },
    async aceptarModalAll() {
      const res = await doTestSrv.checkTest(this.test.id);
      this.rightAnswers = res.data;
      this.modal.show = false;
      this.pending = false;
      this.updateResults();
    },
    exitTest() {
      if (this.pending) {
        this.modal.head = "attention";
        this.modal.body = "test";
        this.modal.show = true;
      }
      this.$router.push("/");
    },
    async getData() {
      const id = this.$route.params.id;
      if (id) {
        try {
          this.SET_OVERLAY(true);
          const res = await doTestSrv.getTest(id);

          if (res.data === "test not found") {
            this.mixShowAlert("test-not-found", "warning");
          } else {
            this.test = res.data.test;
            this.questions = res.data.questions;
          }
        } catch (e) {
          console.log("error", e);
        } finally {
          this.SET_OVERLAY(false);
        }
      }
    },

    updateResults() {
      this.counting = true;
      this.answers.forEach((e) => {
        // localizamos el id de la respuesta
        const tmp = this.rightAnswers.find((r) => r.id === e.question_id);
        const ra = tmp.question_answers[0].id;
        e.result = ra === e.answer_id;
      });
      this.counting = false;
      // console.log({ ...this.answers });
    },

    nextStep(n) {
      if (n === this.compSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
  },

  computed: {
    compSteps() {
      return this.questions.length;
    },
    compStatus() {
      const breakP = this.$vuetify.breakpoint.smAndDown;
      const txt1 = breakP
        ? this.$t("answered").substring(0, 3)
        : this.$t("answered");
      const txt2 = breakP ? this.$t("total").substring(0, 3) : this.$t("total");

      return `${txt1}: ${this.answers.length} ${txt2}: ${this.questions.length}`;
    },
    compResult() {
      const breakP = this.$vuetify.breakpoint.smAndDown;

      if (this.counting) {
        return null;
      }

      const txt1 = breakP ? this.$t("right").substring(0, 3) : this.$t("right");
      const txt2 = breakP
        ? this.$t("errors").substring(0, 3)
        : this.$t("errors");
      const rTrue = this.answers.filter((a) => a.result === true).length;
      const rFalse = this.answers.filter((a) => a.result !== true).length;

      return `${txt1}: ${rTrue} ${txt2}: ${rFalse}`;
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped></style>
